var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"title":_vm.bannerModal.type == 'edit' ? 'Editar Baner' : 'Registrar Baner',"modal-class":"modal-primary","hide-footer":"","size":"lg","loading":""},on:{"hidden":_vm.close},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('validation-observer',{ref:"checkForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"user-last-name"}},[_vm._v("Nombre")])]),_c('validation-provider',{attrs:{"name":"name","vid":"name","rules":"chat-compose-required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{class:{ 'border-danger rounded': errors[0] },attrs:{"type":"text","placeholder":"Ingrese el nombre del banner"},model:{value:(_vm.banner.name),callback:function ($$v) {_vm.$set(_vm.banner, "name", $$v)},expression:"banner.name"}})],1)]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1 d-flex justify-content-center",attrs:{"cols":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white text-center"},[_c('label',{attrs:{"for":"image"}},[_vm._v("Imagen")])]),_c('validation-provider',{attrs:{"name":"image","vid":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group cursor-pointer m-0",on:{"click":function($event){return _vm.focusInputImage()}}},[_c('input',{ref:"inputImage",class:{ 'border-danger rounded': errors[0] },attrs:{"type":"file","id":"image","accept":"image/*","hidden":"","required":""},on:{"change":_vm.getImage}}),(
                    _vm.banner.imageBanner == null || _vm.banner.imageBanner == ''
                  )?_c('b-avatar',{staticClass:"image-banner",attrs:{"size":"300px","square":"","icon":"cloud-upload"}}):_c('figure',{staticClass:"text-center"},[_c('img',{staticClass:"image-banner",attrs:{"src":_vm.imageShow,"alt":"Icon"}})])],1)]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mx-1",attrs:{"type":"submit","variant":"light","disabled":_vm.disableAll}},[_vm._v(" Borrar ")]),(!_vm.loading)?_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.disableAll},on:{"click":function($event){return _vm.register()}}},[_vm._v(" Guardar ")]):_c('b-button',{attrs:{"variant":"primary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Cargando... ")],1)],1)])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }